<template>
<v-app class="extensionH5">
  
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/tuiguangH5_banner.png"
        height="100%"
        class="d-flex align-center"
      >
       <div style="width:92%;height:50px;margin:-28px auto 9px;" >
           <img src='https://h5.ophyer.cn/official_website/other/H5Logo.png' style="width:112px" />
        </div>
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        
        <v-container color="transparent" class="" style="width:100%;height:100%">
          <v-card color="transparent" flat style="width:100%">
            <div class="" style="width:64%">
              <div style="width:100%">
                <v-card-title class="white--text  py-2 font-size-h5banner-title">
                  产品数字化+虚拟现实 
                  <br/>
                  构建运营元宇宙
                </v-card-title>
                <v-card-title class="white--text font-size-h5banner-tip  py-1  px-4">
                   虚拟、超沉浸体验，重塑品牌影响力
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 定制服务介绍 -->
    <v-card class="py-6" flat color="transparent">
        <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">{{data.module.title}}</v-card-title>
        <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">{{data.module.subtitle}}</v-card-text>
      <div class="mt-4">
        <v-row v-for="(row,rowIndex) in data.module.itemList" :key="rowIndex">
          <v-card  color="transparent" :class="['pa-3 d-flex h5-card',rowIndex % 2 == 0 ?'':'justify-flex-end']" width="100%" elevation="0" >
              <v-col cols="6" v-if="rowIndex % 2 == 0" >
                  <v-img class="flex-grow-0" :src="row.icon" cover position="top"></v-img>
              </v-col>
            <v-col cols="6">
                <div>
                <v-card-title class="pa-0  font-size-15 line-height-20">
                    {{row.title}}
                </v-card-title>
                <v-card-text class="pa-0  font-size-12 color-666" style="line-height:18px" v-html='row.explain'>
                    {{row.explain}}
                </v-card-text>
                </div>
            </v-col>
            <v-col cols="6" v-if="rowIndex % 2 != 0">
                <v-img class="flex-grow-0" :src="row.icon" cover position="top"></v-img>
            </v-col>
          </v-card>
        </v-row>
      </div>
    </v-card>
    <!-- 我们的优势 -->
    <div class="width-per-100 pb-3 px-4 mt-10">
       <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">我们的优势</v-card-title>
       <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">5G时代 + 政策扶持时代机遇，顺势而为</v-card-text>
       <v-tabs v-model="advantageTab" background-color="transparent" color="white" dark centered hide-slider
               class="xs-tab">
         <v-tab v-for="(v, k) in advantageList" :key="k" style="min-width: unset;padding: 0 5px;">
           <div class="tab-name-btn" :class="advantageTab === k ? 'tab-active':''">{{ v.title }}</div>
         </v-tab>
       </v-tabs>
       <v-tabs-items class="mt-3 mx-1 transparent" v-model="advantageTab" style="border-radius: 10px;">
         <v-tab-item v-for="(v, k) in advantageList" :key="k" :transition="false">
           <v-card flat height="100%" class="pa-5 pb-0">
             <div v-for="(info,index) in v.children" :key="index" class="">
               <v-card-title class="pa-0 font-size-15 line-height-16 font-weight-bold">
                 <div class="tip_label"></div>
                 {{info.title}}
               </v-card-title>
               <v-card-text class="pa-0 pb-5 mt-2 line-height-17 color-666 font-size-12">{{info.tip}}</v-card-text>
             </div>
           </v-card>
         </v-tab-item>
       </v-tabs-items>
    </div>
    <!-- 合作伙伴 -->
    <div class="width-per-100 pb-3 px-4 mt-10">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">合作伙伴</v-card-title>
      <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">他们都在用</v-card-text>
      <v-img class="mt-3" width="100%" contain src="https://h5.ophyer.cn/official_website/other/h5Home05.png">
        <template v-slot:placeholder><img-placeholder></img-placeholder></template>
      </v-img>
    </div>
    <!-- 获取完整的AR/VR趣味运营运营方案 -->
    <div class="info mt-6">
       <div class="right">
          <p class="mt-6">获取完整的AR/VR趣味运营运营方案</p>
          <h5>即刻填写信息获取细分行业的落地方案吧</h5>
           <v-form
             ref="form_two"
             v-model="valid"
             lazy-validation
             class="mt-3"
           >
             <v-row justify="space-between">
               <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form_two.name"
                    label="* 姓名"
                    :rules="nameRules"
                    color="#FFFFFF"
                    required
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form_two.phone"
                    label="* 电话"
                    :rules="moblieRules"
                    color="#FFFFFF"
                    required
                  ></v-text-field>
               </v-col>
             </v-row>
             <v-row justify="space-between">
               <v-col cols="24" md="12">
                  <v-text-field
                     v-model="form_two.conpanyName"
                     label="* 公司名称"
                     color="#FFFFFF"
                     :rules="comeRules"
                     required
                  ></v-text-field> 
               </v-col>
             </v-row>

             <v-btn 
               :disabled="!valid"
               class="mb-4"
               @click="validate"
             >  
             立即申请
             </v-btn>
           </v-form>         
      </div>
    </div>
    <!-- 回到顶部 -->
    <transition name="fade" appear>
      <div class="hover-btn"
           v-show="officialWebsite.showReturnTip"
           @click.stop="$vuetify.goTo(0, {})">
        <v-img src="../../../../public/img/icons/menu/returnTop.png"></v-img>
      </div>
    </transition>
    <!-- 底部菜单 -->
    <v-dialog v-model="dialog" content-class="susDialog" :transition="false">
      <div class="suspend-dialog">
        <div class="content-phone"  v-show="currentHover === 'phone'">
          <v-card color="transparent" class="position-r" flat>
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="../../../../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-row class="pa-0 justify-center mt-4">
              <v-card-title class="pa-0 justify-center font-size-18 line-height-25 font-weight-bold">联系我们</v-card-title>
            </v-row>
            <v-row class="pa-0 mt-5 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img contain src="../../../../public/img/icons/menu/cooperation.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n1">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-16 font-weight-bold line-height-22 mt-2">
                    商户合作
                  </v-card-title>
                  <v-card-text class="pa-0 font-size-14 line-height-20 mt-1">yizhe.han@ophyer.com</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-7 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img width="40" height="40" contain src="../../../../public/img/icons/menu/consult.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n1">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-16 font-weight-bold line-height-22  mt-2">
                    SaaS 产品咨询
                  </v-card-title>
                  <v-card-text class="pa-0 mt-1 font-size-14 line-height-20">010-63037996</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-7 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img width="40" height="40" contain src="../../../../public/img/icons/menu/joinIn.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n1">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-16 font-weight-bold line-height-22  mt-2">
                    招商加盟
                  </v-card-title>
                  <v-card-text class="pa-0 mt-1 font-size-14 line-height-20">13916184657</v-card-text>
                </v-card>
              </div>
            </v-row>
          </v-card>
        </div>
        <div class="content-follow"  v-show="currentHover === 'share'">
          <div class="close-btn" @click.stop="handleCloseDialog">
            <v-img max-width="12" contain src="../../../../public/img/icons/menu/h5_close_btn.png"></v-img>
          </div>
          <v-card color="transparent" flat class="justify-center text-align-center pa-4" style="height:488px;">
            <v-img src='https://h5.ophyer.cn/official_website/other/tuigaung_link.png'>
               <v-card-title class="pa-0 ">
                 <div class="justify-center text-align-center" style="text-align:center;margin:0 auto">
                   <div  class="pt-16 font-size-24" style="font-weight: 300;color: #FFFFFF;">产品数字化+虚拟现实 </div>
                   <!-- <div  class="pt-2 font-size-14" style="font-weight: 300;color: #FFFFFF;">助力企业开启数字运营新时代</div> -->
                   <div  class="pt-2 font-size-14" style="font-weight: 300;color: #FFFFFF;">助力企业开启数字化新时代</div>
                 </div>
               </v-card-title>
               <div class="code_info">
                 <vue-qr
                    :text="qrurl"
                    :size="130"
                    :margin="10"
                    :correctLevel="1"
                    style="margin:166px auto 0"
                 ></vue-qr>
                </div>
            </v-img>
          </v-card>
        </div>
        <div class="content-tryout"  v-show="currentHover === 'tryout'">
          <v-card color="transparent" flat width="100%" class="position-r">
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="../../../../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-card-title class="justify-center text-align-center font-size-18 pa-0 line-height-25 font-weight-bold">完善信息<br/>专属顾问马上联系您</v-card-title>
            <v-card-text class="text-align-center font-size-12 line-height-17 pa-0 mt-2">苏经理：18511864107</v-card-text>
            <v-form class="pa-0 mt-3" ref='form' v-model="valid" lazy-validation>
              <v-container class="pa-0">
                <v-row class="pa-0">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    姓名
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的姓名" clearable  :rules="rules.name" v-model="form.name"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    电话
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的电话" clearable :rules="rules.phone" v-model="form.phone"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    公司名称
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的公司名称" clearable persistent-hint :rules="rules.conpanyName" v-model="form.conpanyName"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">地区</div>
                  <v-text-field class="width-per-100" placeholder="请输入您所在的地区" persistent-hint v-model="form.address"></v-text-field>
                </v-row>
                <v-row class="pa-0 justify-center mt-5">
                  <div class="tryout-btn" @click.stop="handleSaveTryout">提交</div>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </div>
        <div class="content-agent"  v-show="currentHover === 'agent'">
          <v-card color="transparent" class="position-r" flat>
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="../../../../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-row class="pa-0 justify-center mt-5">
              <v-card-title class="pa-0 justify-center font-size-18 line-height-25 font-weight-bold">我要代理</v-card-title>
            </v-row>
            <v-row class="pa-0 mt-4 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img contain src="../../../../public/img/icons/menu/agent-icon.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-15 font-weight-bold line-height-20">
                    韩先生
                  </v-card-title>
                  <v-card-text class="pa-0 font-size-17 line-height-20">
                    <a href="tel:139-1618-4657" style="color: #2573E2;text-decoration: none">13916184657</a>
                  </v-card-text>
                </v-card>
              </div>
            </v-row>
          </v-card>
        </div>
      </div>
    </v-dialog>

    <div class="Ophyer">
      Copyright @ 2021 . Ophyer All right reserved
    </div>
    
    <v-bottom-navigation :value="bottomMenuVal" color="757575" app grow height="70px">
      <v-btn @click.stop="handleSuspend('tryout')">
        <div class="mt-1">申请试用</div>
        <v-img max-width="40" max-height="40" contain src="../../../../public/img/icons/menu/h5-btn03.png"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('login')">
        <div class="mt-1">注册登录</div>
        <v-img max-width="40" max-height="40" contain src="../../../../public/img/icons/user.png"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('phone')">
        <div class="mt-1">联系我们</div>
        <v-img max-width="40" max-height="40" contain src="../../../../public/img/icons/menu/h5-btn02.png"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('share')">
        <div class="mt-1">分享</div>
        <v-img max-width="40" max-height="40" contain src="../../../../public/img/icons/share.png"></v-img>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
let _this;
import lavData from '@/static/lavData.js'
import { mapState } from "vuex";
import VueQr from 'vue-qr/src/packages/vue-qr.vue'
// import qs from 'qs'
export default {
  name: 'Home',
  components: {
      VueQr
  },
  computed: {
    ...mapState(["officialWebsite"]),
  },
  data() {
    return {
      data:lavData.extensionH5,
      // tab: null,
      advantageTab: null,
      timer:null,
      dialog: false,
      valid: true,
      name: '',
      nameRules: [
        v => !!v || '您的姓名不能为空',
      ],
      moblieRules: [
        v => !!v || '您的电话不能为空',
        v => /^1[3-9]\d{9}$/.test(v) || '请输入正确的手机号',
      ],
      comeRules: [
        v => !!v || '您的公司名称不能为空',
      ],
      form_two:{
        name: "",
        phone: "",
        conpanyName: "",
      },
      currentHover: "",
      form: {
        name: "",
        phone: "",
        conpanyName: "",
        address: ""
      },
      rules: {
        name: [
          value => !!value || '请输入姓名!',
        ],
        phone: [
          value => !!value || '请输入电话!',
          value => /^1[3-9]\d{9}$/.test(value) || '请输入正确的手机号',
          
        ],
        conpanyName: [
          value => !!value || '请输入公司名称!',
        ]
      },
      qrurl:'',  //推广页面地址
      vue_node_env:'',  //saas平台地址
      bottomMenuVal: 1,
      advantageList: [
        {
          title: "市场广阔",
          children: [
            {
              title: "政策扶持",
              tip: "近年来国家不断推出政策、发展基金等助推产业发展；国家十四五规划将AR/VR技术正式写入数字经济重点产业领域"
            },
            {
              title: "5G+ 时代",
              tip: "5G时代来临，信息网络更加快速、稳定成熟，为AR/VR的发展提供了良好的契机"
            },
            {
              title: "产业聚集",
              tip: "随着VR技术的更迭，VR产业逐渐形成规模化、应用化，市场需求量骤增"
            }
          ]
        },
        {
          title: "技术稳定",
          children: [
            {
              title: "技术基础坚实",
              tip: "10年技术沉淀，深耕AR/VR、AI及运营领域，在国内的AR/VR智慧运营领域名列前茅"
            },
            {
              title: "全栈自研技术",
              tip: "独立自主研发AR/VR引擎 ｜ 累计获得软件著作权234个，授权专利4个，作品著作权21个"
            },
            {
              title: "稳定开发团队",
              tip: "100 + 技术团队强有力支撑，团队成员稳定，为系统开发升级保驾护航"
            }
          ]
        },
        {
          title: "服务保障",
          children: [
            {
              title: "智能数据分析",
              tip: "运营活动效果监控，实时追踪活动数据，为运营方案调整提供数据支持"
            },
            {
              title: "多渠道在线客服",
              tip: "7x24小时应急响应，只要您需要，我们随时在身边"
            },
            {
              title: "顾问式运营服务",
              tip: "项目全链路追踪，全程指导，为运营活动持续助力"
            }
          ]
        },
      ],
      as_api:''
    }
  },
  created() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.showDialog = false;
    });
    if(process.env.VUE_APP_NODE_ENV==='development'){
       _this.vue_node_env = 'http://192.168.1.40:8901'
       //_this.bas_api = 'http://122.115.53.160:8812'
       _this.qrurl = 'http://192.168.1.40:8080/wmyH5/extensionH5?sourceAgentId='+this.$route.query.sourceAgentId
    }
    else if(process.env.VUE_APP_NODE_ENV==='test'){
      _this.vue_node_env = 'https://test.iwmy.igame123.com'
      //_this.bas_api = 'https://test.iwmy.igame123.com'
      _this.qrurl = 'https://test.wmy.igame123.com/wmyH5/extensionH5?sourceAgentId='+this.$route.query.sourceAgentId 
    }
    else{
       _this.vue_node_env =  'https://i.wmy.ophyer.com' //登录SaaS平台生产地址
      // _this.bas_api =  'https://i.wmy.ophyer.com'
       _this.qrurl = 'https://wmy.ophyer.com/wmyH5/extensionH5?sourceAgentId='+this.$route.query.sourceAgentId  
    }
  },
  mounted() {
    _this = this;
    clearInterval(_this.timer)
    _this.setTimer()
  },
  methods: {
    setTimer:() =>{
      // _this.timer = setInterval(() => {
      //   _this.advantageTab ++
      // },2000)
    },
    mouseover:()=>{
      clearInterval(_this.timer)
    },
    mouseleave:() => {
      _this.setTimer()
    },

    go_login(){
      console.log(_this.$route.query.sourceAgentId,'_this.$route.query.sourceAgentId')
      var sourceAgentId=_this.$route.query.sourceAgentId
      let url = this.vue_node_env+'?sourceAgentId='+sourceAgentId
      window.open(url, '_blank')
    },
    
    validate () {
     var valid = _this.$refs.form_two.validate()
     if (valid) {
        let param = {};
        param.agentId = this.sourceAgentId;
        param.contact = this.form_two.name;
        param.companyName = this.form_two.conpanyName;
        param.area = this.form_two.address;
        param.contactPhone = this.form_two.phone;
        this.on_save(param)
     } else {
       console.log('验证失败')
       return false
     }
    },
    on_save(param) {
      var _this = this
      let url = process.env.VUE_APP_NODE_ENV==='development'? process.env.VUE_APP_API_DEV:(process.env.VUE_APP_NODE_ENV==='test'?'https://test.iwmy.igame123.com/apiProxy/basic/api/vip/add2':'https://i.wmy.ophyer.com/apiProxy/basic/api/vip/add2')
      // let url = process.env.VUE_APP_NODE_ENV==='development'? process.env.VUE_APP_API_DEV:(process.env.VUE_APP_NODE_ENV==='test'?process.env.VUE_APP_WMY_TEST:process.env.VUE_APP_YMY_PROD)
      // url= url + '/basic/api/vip/add2'
      console.log(url,'url')
      //url='https://test.iwmy.igame123.com'
      _this.$axios.get(url,{params:param}).then(() =>{              
            this.$confirm('我们将在24小时内联系您，请耐心等待', '提交成功', {
                  confirmButtonText: '确定',
                  type: 'success',
                  center: true,
                  customClass:'add_confirm',
                  showCancelButton: false
                }).then(() => {
                  this.$refs.form.reset() 
                    this.form = {
                      name: "",
                      phone: "",
                      conpanyName: "",
                      address: ""
                    }
                })
            }).catch((error) =>{
               _this.$message.error(error)
               console.log(error)       //请求失败返回的数据
            })
    },
    handleSuspend: function (type){
      this.currentHover = type;
      
      this.dialog = true;
      if(type==='login'){
        this.go_login()
      }
    },
    handleCloseDialog: function (){
      this.currentHover = "";
      this.dialog = false;
      this.$refs.form.reset() 
      this.form = {
        name: "",
        phone: "",
        conpanyName: "",
        address: ""
      }
    },
    handleSaveTryout: function (){
      const reg = /^1[3-9]\d{9}$/
      if(!reg.test(this.form.phone)){
        this.$lavMessage.warning("请填写正确的手机号！");
        return;
      }
      if(!this.form.name || !this.form.phone || !this.form.conpanyName){
        this.$lavMessage.warning("请填写必填项！");
        return;
      }
      this.currentHover = "";
      this.dialog = false;
      let param = {};
      param.agentId = this.sourceAgentId;
      param.contact = this.form.name;
      param.companyName = this.form.conpanyName;
      param.area = this.form.address;
      param.contactPhone = this.form.phone;
      this.on_save(param)
      
    },
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
}
.v-tab--active{
    b{
        color: #2181EA !important;
    }
}
.justify-flex-end{
    justify-content: flex-end;
}
.extensionH5{
  background: #F4F8FE;
}
.advantage_card{
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
.flex-grow-0{
    border-radius: 20px;
    box-shadow: 0px 24px 50px 0px rgb(22 133 248 / 20%);
    height: 100%;
}
::v-deep .info{
  background: #2E91F1;
  border-radius: 10px;
  width: 95%;
  margin: 0 auto 30px;

  .right{
    // width: 600px;
    // height: 370px;
    background: #2E91F1;
    border-radius: 8px;
    width: 98%;
    margin: 0 auto;
    text-align: center;
    // padding-left: 44px;
    p{
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px;
    }
    h5{
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 15px;     
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #fff;
    }
  }

  .theme--light.v-label{
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF !important;
    line-height: 21px;
    margin-top: -17px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    width: 92%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    // margin-left: 43px;
    // margin-top: 30px;
  }
 }
.Ophyer{
     width: 100%;
     height: 33px;
     background: #F4F8FE;
     text-align: center;
    //  padding-top:28px;
     font-size: 12px;
     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
     font-weight: 400;
     color: #000000;
}

::v-deep .susDialog{
  overflow-y: unset;
}
.suspend-dialog{
  display: flex;
  justify-content: center;
  .close-btn{
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 15px !important;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    position: absolute;
    left: calc(50% - 15px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-phone{
    width: 300px;
    height: 290px;
    background-color: #FFFFFF;
    border-radius: 10px;
    position: relative;
    .close-btn{
      top: 300px;
    }
  }
  .content-agent{
    width: 230px;
    height: 125px;
    background-color: #FFFFFF;
    border-radius: 10px;
    position: relative;
    .close-btn{
      top: 125px;
    }
  }
  .content-follow{
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding-bottom: 18px;
    position: relative;
    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    };
    .close-btn{
      top: 520px;
    }
  }
  .content-tryout{
    max-width: 500px;
    min-width: 300px;
    width: calc(100vw - 40px);
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 20px 30px 20px 30px;
    position: fixed;
    top: 47% !important;
    //left: 50%;
    z-index: 999;
    transform: translate(0,-50%);
    background-color: #FFFFFF;
    .tryout-btn {
      width: 100%;
      height: 40px;
      background: #2D87FF;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      letter-spacing:8px;
      cursor: pointer;
    }
    .close-btn{
      width: 30px;
      height: 30px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 15px !important;
      border: 1px solid #FFFFFF;
      cursor: pointer;
      position: absolute;
      top: 530px;
      left: calc(50% - 15px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ::v-deep .v-dialog{
    box-shadow: unset;
  }
}
.hover-btn{
  width: 35px;
  height: 35px;
  border-radius: 20px;
  position: fixed;
  bottom: calc(20% - 55px);
  right: 5px;
  z-index:5;
  box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
  cursor: pointer;
}

</style>
<style>
.add_confirm{
  width: 300px;
}
</style>
